import React, { memo, useMemo, Component } from 'react';
import { connect } from 'react-redux';
import { Trans, TFunction, useTranslation, withTranslation } from 'react-i18next';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import moment, { Moment } from 'moment';
import * as R from 'ramda';

import { TopNavigationBar } from '@common/components/navigation-bar/top-navigation-bar';
import QuizChoiceInput from '@common/components/quiz-choice-input/quiz-choice-input';
import * as AlertService from '@common/services/alert';
import Container from '@common/components/container';
import Spinner from '@common/components/spinner';
import { Button } from '@common/components/button';
import Modal from '@common/components/modal';
import Overview from '@common/components/overview';
import { Row, Group, Label, DatePickerInput } from '@common/components/form';
import { AudienceInput } from '@common/components/form/audience';
import { ToggleInput } from '@common/components/form/inputs/toggle';
import TimePickerInput from '@common/components/form/inputs/time';

import * as predicateUtil from '@common/utils/predicates';
import { EPlanPackageConfig } from '@common/definitions';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import * as surveySelector from '../../selectors/surveys';
import { ESurveyTypes } from '../../definitions';
import { Survey } from '@modules/survey/types/objects';
import { StoreState } from '@common/types/store';
import { History } from '@common/types/objects';
import { usePlanPackageAudienceHandlers } from '@common/hooks/use-plan-package-audience-handlers';

import * as surveyActions from '../../actions';

type SurveyDateInputProps = {
  input: {
    value: typeof ESurveyTypes[keyof typeof ESurveyTypes];
  }
};

type SurveyTypeSelectorProps = {
  input: {
    onChange: () => void;
    value: string | null;
  }
};

export const SurveyTypeSelector = memo(({ input: { value, onChange } }: SurveyTypeSelectorProps) => {
  const { t } = useTranslation();
  const options = useMemo(() => {
    return [{
      value: ESurveyTypes.DIRECT,
      label: t('survey:publish_form_type_direct'),
      description: t('survey:publish_form_type_direct_description')
    }, {
      value: ESurveyTypes.ONGOING,
      label: t('survey:publish_form_type_ongoing'),
      description: t('survey:publish_form_type_ongoing_description')
    }];
  }, [t]);
  return <QuizChoiceInput value={value} onChange={onChange} options={options} />;
});

type Match = {
  params: { id: string; };
};

type FormData = {
  type: Survey['survey_type'];
  settings: {
    is_anonymous: boolean;
    expires_at?: Moment;
    publish_at?: Moment;
    publish_date?: Moment;
    publish_time?: string;
  };
  audience: {};
};

type Payload = {
  type: Survey['survey_type'];
  settings: {
    expires_at?: string;
    publish_at?: string;
    is_anonymous: boolean;
  };
  audience: {};
};

type Props = {
  history: History;
  match: Match;
  survey: Survey;
  fetchSurvey: (nextCursor: string) => Promise<Request>;
  updateSurvey: (id: string, payload: Payload) => Promise<Request>;
  t: TFunction;
};

type SurveyPublishProps = InjectedFormProps<FormData, Props, boolean> & Props & {
  planPackageAudienceHandlers: Record<string, Function>;
};

class PublishSurveyContainer extends Component<SurveyPublishProps> {
  UNSAFE_componentWillMount() {
    const { match, survey, fetchSurvey } = this.props;

    if (survey) {
      this.initialise();
    } else {
      fetchSurvey(match.params.id);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.survey && this.props.survey) this.initialise();
  }

  initialise() {
    const { survey, initialize } = this.props;

    initialize({
      type: survey.survey_type,
      settings: {
        is_anonymous: survey.settings.is_anonymous,
        expires_at: (survey.settings.expires_at && moment(survey.settings.expires_at)) || undefined,
        publish_date: moment(),
        publish_time: '12:00',
      },
      audience: {
        predicate_type: survey.audience.predicate_type,
        predicates: survey.audience.predicates.map((predicate, i) => R.assoc('id', i, predicate)),
      },
    });
  }

  async handleSubmit(values: FormData, publish = false, direct = false) {
    const { history, survey, updateSurvey, t } = this.props;

    try {
      const payload: Payload = {
        type: values.type,
        settings: {
          is_anonymous: values.settings.is_anonymous,
        },
        audience: R.evolve({
          predicates: predicateUtil.predicatesToPayload,
        }, values.audience),
      };

      if (values.type === ESurveyTypes.DIRECT) payload.settings.expires_at = values.settings.expires_at?.toISOString();
      if (publish) {
        const time = values.settings.publish_time!.split(':');
        const publishAt = direct ? moment() : values.settings.publish_date!.hours(+time[0]).minutes(+time[1]).seconds(0);
        payload.settings.publish_at = publishAt.toISOString();
      }

      if (survey) await updateSurvey(survey.id, payload);

      AlertService.success(publish ? t('survey:survey_published') : t('survey:survey_saved'));
      history.push('/admin/surveys');
    } catch (response: any) {
      AlertService.forStatus(response.status_code, {
        warning: publish ? t('survey:survey_publish_warning') : t('organisation:survey_save_warning'),
        error: publish ? t('survey:survey_publish_warning') : t('organisation:survey_save_error'),
      });
    }
  }

  render() {
    const {
      history, match: { params }, survey, invalid, handleSubmit, t, planPackageAudienceHandlers,
    } = this.props;

    const submitWrapper = (publish = false, direct = false) => (values: FormData) => {
      this.handleSubmit(values, publish, direct);
    };

    return (
      <form className="Form">
        <Container name="Survey">
          <TopNavigationBar
            breadcrumbs={[
              { name: t('survey:breadcrumb_surveys') },
              survey && { name: survey.title! },
            ]}
            title={survey && survey.title}
            action={(
              <>
                <Button onClick={() => history.push(`/admin/surveys/${params.id}/edit`)}>
                  <Trans i18nKey="survey:go_back" />
                </Button>
                <Button disabled={invalid} onClick={handleSubmit(submitWrapper(false))}>
                  <Trans i18nKey="survey:save_and_close" />
                </Button>
                <Modal
                  list
                  size="small"
                  content={(
                    <>
                      <Row>
                        <Group>
                          <Label text={t('survey:publish_form_publish_date')} />
                          <div style={{ width: 150 }}>
                            <DatePickerInput name="settings.publish_date" minDate={moment()} />
                          </div>
                        </Group>
                      </Row>
                      <Row>
                        <Group>
                          <Label text={t('survey:publish_form_publish_time')} />
                          <div style={{ width: 125 }}>
                            <TimePickerInput name="settings.publish_time" />
                          </div>
                        </Group>
                      </Row>
                    </>
                  )}
                  footer={(
                    <Button
                      type="primary"
                      icon="send"
                      onClick={handleSubmit(submitWrapper(true, false))}
                    >
                      <Trans i18nKey="survey:schedule" />
                    </Button>
                  )}
                >
                  <Button disabled={invalid} type="inverted-primary"><Trans i18nKey="survey:publish_later" /></Button>
                </Modal>
                <Button
                  disabled={invalid}
                  type="primary"
                  icon="send"
                  onClick={handleSubmit(submitWrapper(true, true))}
                >
                  <Trans i18nKey="survey:publish_now" />
                </Button>
              </>
            )}
          />
          {!survey ? (
            <Spinner centered size="large" />
          ) : (
            <Container.Content>
              <Overview>
                <Overview.Content>
                  <Row>
                    <Group>
                      <Label text={t('survey:publish_form_type')} />
                      <Field
                        name="type"
                        component={SurveyTypeSelector}
                      />
                    </Group>
                  </Row>
                  <Row>
                    <Group>
                      <Label
                        text={t('survey:publish_form_anonymous')}
                        description={t('survey:publish_form_anonymous_decsription')}
                      />
                      <ToggleInput name="settings.is_anonymous" />
                    </Group>
                  </Row>
                  <Field
                    name="type"
                    component={({ input: { value } }: SurveyDateInputProps) => value === ESurveyTypes.DIRECT && (
                      <Row>
                        <Group>
                          <Label text={t('survey:publish_form_expiration_date')} />
                          <div className="PublishSurvey__Description">
                            <Trans i18nKey="survey:publish_form_expiration_date_description" />
                          </div>
                          <div style={{ width: 150 }}>
                            <DatePickerInput name="settings.expires_at" minDate={moment().add(1, 'day')} />
                          </div>
                        </Group>
                      </Row>
                    )}
                  />
                  <Row>
                    <Group>
                      <div className="SurveyAudienceSelector">
                        <AudienceInput
                          name="audience"
                          tooltips={{ audience: t('survey:audience_selector_tooltip') }}
                          {...planPackageAudienceHandlers}
                        />
                      </div>
                    </Group>
                  </Row>
                </Overview.Content>
              </Overview>
            </Container.Content>
          )}
        </Container>
      </form>
    );
  }
}

const mapStateToProps = (state: StoreState, { match }: Props) => ({
  organisation: organisationSelector.selected(state),
  survey: surveySelector.item(state, match.params.id),
});

const mapDispatchToProps = {
  fetchSurvey: surveyActions.fetchSurvey,
  updateSurvey: surveyActions.updateSurvey,
};

const WrappedPublishSurveyContainer = (props: SurveyPublishProps) => {
  const planPackageAudienceHandlers = usePlanPackageAudienceHandlers({
    packageIdAddFilters: EPlanPackageConfig.SURVEYS_AUDIENCE_SELECTOR,
    packageIdDaysInService: EPlanPackageConfig.SURVEYS_AUDIENCE_SELECTOR_ONBOARDING,
  });
  return <PublishSurveyContainer {...props} planPackageAudienceHandlers={planPackageAudienceHandlers} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(reduxForm({
  form: 'publish-survey',
  initialValues: {
    type: 'direct',
    settings: {
      is_anonymous: false,
      expires_at: null,
      publish_date: moment(),
      publish_time: '12:00',
    },
    audience: {
      predicate_type: predicateUtil.ESearchPredicateTypes.MATCH_ANY,
      predicates: [],
    },
  },
})(pageWrapper(EEventNames.VISITED_SURVEY_PUBLISH_PAGE)(WrappedPublishSurveyContainer))));
